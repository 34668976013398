import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetCash = (branchId, config) =>
    axiosClient.get(`/payment-cash/${branchId}`, config);

const UpdateCash = (branchId, id, data, config) =>
    axiosClient.patch(`/payment-cash/${branchId}/${id}`, data, config);

export default {
    GetCash,
    UpdateCash
};