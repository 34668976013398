import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const CreateUnit = (data, config) =>
    axiosClient.post(`/business-product/unit`, data, config);

const GetAllUnit = (config) =>
    axiosClient.get(`/business-product/unit`, config);

const GetUnitById = (id, config) =>
    axiosClient.get(`/business-product/unit/${id}`, config);

const UpdateUnit = (id, data, config) =>
    axiosClient.patch(`/business-product/unit/${id}`, data, config);

const DeleteUnit = (id, config) =>
    axiosClient.delete(`/business-product/unit/${id}`, config);

export default {
    GetAllUnit,
    CreateUnit,
    GetUnitById,
    UpdateUnit,
    DeleteUnit
};
