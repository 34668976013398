import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const CreteTransfer = (branchId ,data, config) =>
    axiosClient.post(`/payment-transfer/${branchId}`, data, config);

const GetTransfer = (branchId, config) =>
    axiosClient.get(`/payment-transfer/${branchId}`, config);

const GetTransferById = (branchId, id, config) =>
    axiosClient.get(`/payment-transfer/${branchId}/${id}`, config);

const UpdateTransfer = (branchId, id, data, config) =>
    axiosClient.patch(`/payment-transfer/${branchId}/${id}`, data, config);

const DeleteTransfer = (branchId, id, config) =>
    axiosClient.delete(`/payment-transfer/${branchId}/${id}`, config);

export default {
    CreteTransfer,
    GetTransfer,
    GetTransferById,
    UpdateTransfer,
    DeleteTransfer
};