import React from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";

export default function BusinesUnitTable({
  filteredItems,
  setUniteditdata,
  setModaleditunitShow,
  getUnitById,
}) {
  const columns = [
    {
      name: "หน่วยนับ",
      selector: (row) => row.unitCode,
      width: "150px",
    },
    {
      name: "ชื่อหน่วยนับสินค้า",
      selector: (row) => row.unitName,
      cell: (row, index, column, id) => (
        <div style={{ color: "#565DFF", fontWeight: 500 }}>{row.unitName}</div>
      ),
    },
    {
      name: "ประเภทหน่วยนับ",
      selector: (row) => row.unitType,
      cell: (row, index, column, id) => (
        // row.unitType == 1 ? (
        //   <div>หน่วยนับสินค้าสำเร็จรูป</div>
        // ) : (
        //   <div>หน่วยนับสินค้าวัตถุดิบ</div>
        // ),
        <>{row.unitType}</>
      ),
    },
    {
      name: "สถานะ",
      selector: (row) => row.unitStatus,
      center: "true",
      width: "200px",
      cell: (row, index, column, id) => {
        const status = row.unitStatus ? "1" : "0";
        return <div className={"datalist-status status" + status}></div>;
      },
    },
    {
      name: "จัดการ",
      selector: (row) => row.unitID,
      center: "true",
      width: "150px",
      cell: (row, index, column, id) => {
        return (
          <div>
            <Button
              type="button"
              className="btn-edit-st-1 "
              onClick={() => {
                //   setUniteditdata(row);
                //   setModaleditunitShow(true);
                getUnitById(row.unitID);
              }}
            ></Button>
          </div>
        );
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "18px",
        background: " #F5F5F7",
        paddingTop: "15px",
        paddingBottom: "15px",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "18px",
        fontWeight: "300",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      customStyles={customStyles}
      //   selectableRows
      //   selectableRowsComponent={Checkbox}
      pagination
      paginationComponentOptions={paginationComponentOptions}
    />
  );
}
