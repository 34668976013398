import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import data from "libs/unitType/data";

const UnitEditModal = (props) => {
  const schema = yup.object().shape({
    code: yup.string().required(),
    name: yup.string().required(),
  });

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#565DFF" }}>
            แก้ไขหน่วยนับ
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.uniteditdata}
            validationSchema={schema}
            onSubmit={async (values) => {
              props.onSubmit(values);
              props.onHide();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    รหัสหน่วยนับ <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    className="form-control"
                    placeholder="รหัสหน่วยนับ"
                    value={values.code}
                    onChange={handleChange}
                    isInvalid={!!errors.code}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อหน่วยนับสินค้า <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="ชื่อหน่วยนับสินค้า"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                </Form.Group>

                <div>
                  <Form.Label className="mb-2  tc-10">
                    หน่วยนับสินค้า
                  </Form.Label>
                  <div className="mb-3">
                    <Form.Select
                      aria-label="Unit Type Select"
                      className="form-control form-select form-select-st-1 mt-2"
                      name="unit_type_id"
                      value={values.unit_type_id}
                      onChange={(e) => {
                        // format value to number
                        const value = parseInt(e.target.value);
                        setFieldValue("unit_type_id", value);
                      }}
                    >
                      {data.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={item.id == values.unit_type_id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="is_active"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.is_active}
                      onChange={(e) => {
                        setFieldValue("is_active", true);
                      }}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="is_active"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={!values.is_active}
                      onChange={(e) => {
                        setFieldValue("is_active", false);
                      }}
                    />
                  </div>
                ))}

                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3 form-st-3 pb-1 ">
                      <Form.Label className="mb-1">วันที่แก้ไข </Form.Label>
                      <Form.Control
                        type="text"
                        name="updated_at"
                        className="form-control"
                        placeholder="วันที่สร้าง"
                        value={values.updated_at}
                        onChange={handleChange}
                        isInvalid={!!errors.updated_at}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3 form-st-3 pb-1 ">
                      <Form.Label className="mb-1">ชื่อผู้แก้ไข </Form.Label>
                      <Form.Control
                        type="text"
                        name="updated_by"
                        className="form-control"
                        placeholder="ชื่อผู้สร้าง"
                        value={values.updated_by}
                        onChange={handleChange}
                        isInvalid={!!errors.updated_by}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                  <Button
                    type="button"
                    className="btn-st-5 me-auto "
                    style={{ width: "80px" }}
                    onClick={() => {
                      props.onDelete();
                      props.onHide();
                    }}
                  >
                    ลบ
                  </Button>
                  <Button
                    type="button"
                    className="btn-st-6 me-3 "
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    อัพเดท
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnitEditModal;
