import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import useToken from "utils/UseToken";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import cashApi from "services/business/payment/cashApi";

export default function Cashpaymentsetting() {
  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { branchID } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const configCreate = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "business-id": user.businessId,
    },
  };

  const [paymentsetting, setPaymentsetting] = useState({
    id: 0,
    is_active: false,
  });

  const handleChange = (e) => {
    if (e.target.checked === true) {
      setPaymentsetting((prev) => ({ ...prev, is_active: true }));
    } else {
      setPaymentsetting((prev) => ({ ...prev, is_active: false }));
    }
  };

  const handleSubmit = () => {
    // console.log("🚀 ~ handleSubmit ~ paymentsetting:", paymentsetting)
    const data = {
      ...paymentsetting,
      branch_id: parseInt(branchID),
    };

    try {
      const res = cashApi.UpdateCash(
        branchID,
        paymentsetting.id, 
        data, 
        configCreate
    );

    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error", error);
    }
  };

  useEffect(() => {
    if (user.businessId && token) {
      GetCash();
    }
  }, [user.businessId, token]);

  const GetCash = async () => {
    const res = await cashApi.GetCash(branchID, config);

    const data = res.data[0];
    setPaymentsetting(data);
  };

  return (
    <div>
      <Form.Check
        type="switch"
        id="cashpayment"
        label="ตั้งค่าการชำระเงิน"
        name="cashpayment"
        className="custom-switch-st-1 tc-10  s1 mb-1"
        onChange={(e) => {
          handleChange(e);
        }}
        checked={paymentsetting.is_active}
      />

      <div className="text-end mt-5  pt-5 pb-2 btn-group-control">
        <Button
          type="submit"
          className="btn-st-3  "
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          {" "}
          บันทึก
        </Button>
      </div>
    </div>
  );
}
