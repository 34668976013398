import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const GetQr = (branchId, config) =>
    axiosClient.get(`/payment-QR/${branchId}`, config);

const UpdateQr = (branchId, id, data, config) =>
    axiosClient.patch(`/payment-QR/${branchId}/${id}`, data, config);

export default {
    GetQr,
    UpdateQr
};