import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { BranchEmployeeSchema } from "libs";
import SuccessDeleteModal from "components/branch/user/SuccessDeleteModal";
import ConfirmDeleteModal from "components/branch/user/ConfirmDeleteModal";
import Form from "react-bootstrap/Form";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import EmpApi from "services/emp/EmpApi";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { appState } from "state/app";
import useToken from "utils/UseToken";

const EmployeeManage = () => {

  const { branchID, permissionID } = useParams();

  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);

  const [modalsuccessShow, setModalsuccessShow] = useState(false);
  const [optionsPermission, setOptionsPermission] = useState([
    { value: 1, label: "BusinessAdmin" },
    { value: 2, label: "BranchSuperAdmin" },
    { value: 3, label: "BranchAdmin" },
    { value: 4, label: "Kitchen" },
    { value: 5, label: "Waiter" },
    { value: 6, label: "Cashier" },
  ]);
  const [empdata, setEmpdata] = useState([]);
  const schema = BranchEmployeeSchema;
  const [formdisabled, setFormdisabled] = useState(false);
  const [pdata, setPdata] = useState({
    empID: "",
    empCode: "",
    empName: "",
    empEmail: "",
    permission: "",
    usePin: "",
    pin: "",
    pinConfirm: "",
  });
  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { slug } = useRecoilValue(appState);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
      "branch-id": branchID,
    },
  };

  const getempData = () => {
    EmpApi.GetAllEmpByBussiness(config).then((res) => {
      const data = res.data;
      data.map((item) => {
        item.empID = item.id;
        item.empCode = item.code;
        item.empName = item.first_name_th + " " + item.last_name_th;
        item.email = item.email;
      });
      setEmpdata(data);
    });
  };

  const createUser = (values) => {
    console.log("🚀 ~ createUser ~ values:", values)
    try {
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  useEffect(() => {
    if (user.businessId && token && branchID) {
      getempData();
    }
  }, [user.businessId, token]);

  const handleOnSelect = (item) => {
    setPdata((prev) => ({
      ...prev,
      empID: item.empID,
      empCode: item.empCode,
      empName: item.empName,
      empEmail: item.email,
    }));
  };

  const formatResult = (item) => {
    return (
      <div
        className="d-flex align-items-center"
        key={item.empID + item.empCode}
      >
        <div
          className="me-2 fs-14"
          style={{ display: "block", color: "#565DFF" }}
        >
          {item.empCode}
        </div>
        <div>{item.empName}</div>
      </div>
    );
  };

  return (
    <div style={{ padding: "30px 33px 33px 31px" }}>
      <div className="card card-st-2 mb-4">
        <div className="card-body px-xl-4 py-4">
          <Formik
            enableReinitialize
            initialValues={pdata}
            validationSchema={schema}
            onSubmit={async (values) => {
              console.log(values);
              const createNewUser = await createUser({
                ...values,
                employee_id: values.empID,
                businessId: user.businessId,
                branch_id: branchID,
                service_id: 2,
                role_id: values.permission,
                pin_code: values.pin,
                is_pin_active: values.usePin,
                user_name: values.empEmail,
              });
              //   navigate("/branch/setting/emp/1");
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form
                className=" "
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="d-flex align-items-center  mb-4 ">
                  <div
                    className="fs-18 fw-bold me-auto"
                    style={{ color: "#565DFF" }}
                  >
                    {" "}
                    {permissionID ? "แก้ไขข้อมูลพนักงาน" : "ดึงข้อมูลพนักงาน"}
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-12">
                      <fieldset disabled={formdisabled}>
                        <div className="row">
                          <div className="col-xl-4">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label
                                className="mb-1"
                                style={{ zIndex: "12" }}
                              >
                                ค้นหาพนักงาน{" "}
                              </Form.Label>
                              <ReactSearchAutocomplete
                                items={empdata}
                                placeholder="ค้นหาพนักงาน"
                                onSelect={handleOnSelect}
                                autoFocus
                                formatResult={formatResult}
                                fuseOptions={{
                                  keys: ["empID", "empCode", "empName"],
                                }}
                                resultStringKeyName="empName"
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4">
                            <Form.Group className="mb-3 form-st-3 pb-1 ">
                              <Form.Label className="mb-1">
                                รหัสพนักงาน{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="empCode"
                                className="form-control"
                                placeholder="รหัสพนักงาน"
                                value={values.empCode}
                                onChange={handleChange}
                                isInvalid={!!errors.empCode}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 mt-3 form-st-3 pb-1  ">
                              <Form.Label className="mb-1">
                                ชื่อพนักงาน
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="empName"
                                className="form-control"
                                placeholder="ชื่อพนักงาน"
                                value={values.empName}
                                onChange={handleChange}
                                isInvalid={!!errors.empName}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">E-mail </Form.Label>
                              <Form.Control
                                type="text"
                                name="empEmail"
                                className="form-control"
                                placeholder="E-mail "
                                value={values.empEmail}
                                onChange={handleChange}
                                isInvalid={!!errors.empEmail}
                                disabled={true}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3 align-self-center">
                            <div style={{ width: "160px" }}>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="เปิดใช้งาน PIN"
                                name="usePin"
                                className="custom-switch-st-1 tc-10 s2 mb-1"
                                defaultChecked={values.usePin}
                                checked={values.usePin === 1}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setFieldValue("usePin", 1);
                                  } else {
                                    setFieldValue("usePin", 0);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xl-5">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                รหัส PIN 4 หลัก{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="pin"
                                className="form-control"
                                placeholder="รหัส PIN 4 หลัก"
                                value={values.pin}
                                onChange={handleChange}
                                isInvalid={!!errors.pin}
                                disabled={values.usePin != 1}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ยืนยันรหัส PIN 4 หลัก{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="pinConfirm"
                                className="form-control"
                                placeholder="ยืนยันรหัส PIN 4 หลัก"
                                value={values.pinConfirm}
                                onChange={handleChange}
                                isInvalid={!!errors.pinConfirm}
                                disabled={values.usePin != 1}
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="card card-st-1 mt-4 mb-4">
                          <div className="card-body px-0">
                            <div
                              className="fs-18 px-3 fw-bold me-auto mb-3"
                              style={{ color: "#565DFF" }}
                            >
                              สิทธิการเข้าถึง
                            </div>
                            <table className="table table-st-1">
                              <thead>
                                <tr>
                                  <th>ตำแหน่ง</th>
                                  <th className="text-center">ครัว</th>
                                  <th className="text-center">แคชเชียร์</th>
                                  <th className="text-center">พนักงานเสริฟ</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Back - Office</td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>POS</td>
                                  <td>
                                    <div className="icon-p2"></div>
                                    <div className="text-center fs-12">
                                      หน้าจอครัว
                                    </div>
                                  </td>
                                  <td>
                                    <div className="icon-p2"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mobile</td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p1"></div>
                                  </td>
                                  <td>
                                    <div className="icon-p2"></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-4">
                            <Form.Group className="mb-3 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                สิทธิผู้ใช้งานระดับสาขา{" "}
                              </Form.Label>
                              <Select
                                as="select"
                                name="permission"
                                onChange={(e) => {
                                  setFieldValue("permission", e.value);
                                }}
                                className="w-100 select-st-1"
                                options={optionsPermission}
                                placeholder="ค้นหาสิทธิผู้ใช้งาน"
                                value={optionsPermission.filter(
                                  (item) => item.value === values.permission
                                )}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div className="text-end pb-4 pt-4 pe-3">
                  {formdisabled === true ? (
                    <Button
                      type="button"
                      className="btn-st-3 mx-3"
                      onClick={(e) => {
                        setFormdisabled(false);
                      }}
                    >
                      แก้ไข
                    </Button>
                  ) : (
                    <div className=" pt-3 d-flex btn-group-control w-100">
                      {permissionID ? (
                        <Button
                          type="button"
                          className="btn-st-5 me-auto "
                          //  style={{width:'80px'}}
                          onClick={() => setModalDeleteShow(true)}
                        >
                          นำออกจากข้อมูล
                        </Button>
                      ) : (
                        <div className="me-auto"></div>
                      )}

                      <Link
                        to={`/branch/setting/emp/${branchID}`}
                        className="btn-st-6 me-3 btn text-decoration-none "
                      >
                        ยกเลิก
                      </Link>
                      <Button type="submit" className="btn-st-3 ">
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>

                <ConfirmDeleteModal
                  show={modalDeleteShow}
                  onHide={() => setModalDeleteShow(false)}
                  onConfirm={() => setModalDeleteShow(false)}
                  onSuccess={() => {
                    setModalsuccessShow(true);
                    setDeleteStatus(true);
                  }}
                  pdata={pdata}
                />
                <SuccessDeleteModal
                  show={modalsuccessShow}
                  onHide={() => setModalsuccessShow(false)}
                  modalObj={{
                    modalsuccessShow,
                    setModalsuccessShow,
                    deleteStatus,
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EmployeeManage;
