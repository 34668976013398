import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Transferpaymentsetting from "pages/branch/payment/transfer";
import QRpaymentsetting from "pages/branch/payment/qrcode";
import Creditpaymentsetting from "pages/branch/payment/credit";

import Deliverypaymentsetting from "pages/branch/payment/delivery";
import Cashpaymentsetting from "pages/branch/payment/cash";
import Debtorspaymentsetting from "pages/branch/payment/debtors";
import Pointpaymentsetting from "pages/branch/payment/point";
import Otherpaymentsetting from "pages/branch/payment/other";

export function Payment() {
  const [paymenttype, setPaymenttype] = useState(1);
  // array ของ paymenttype
  const paymenttypeArray = [
    {
      id: 1,
      name: "เงินสด",
      component: <Cashpaymentsetting />,
      active: true,
    },
    {
      id: 2,
      name: "QR CODE",
      component: <QRpaymentsetting />,
      active: false,
    },
    {
      id: 3,
      name: "โอนเงิน",
      component: <Transferpaymentsetting />,
      active: true,
    },
    {
      id: 4,
      name: "Delivery",
      component: <Deliverypaymentsetting />,
      active: true,
    },
    {
      id: 5,
      name: "บัตรเครดิต/เดบิต",
      component: <Creditpaymentsetting />,
      active: true,
    },
    {
      id: 6,
      name: "บันทึกลูกหนี้",
      component: <Debtorspaymentsetting />,
      active: false,
    },
    {
      id: 7,
      name: "ช่องทางอื่นๆ",
      component: <Pointpaymentsetting />,
      active: false,
    },
    {
      id: 8,
      name: "ตั้งค่าอื่นๆ",
      component: <Otherpaymentsetting />,
      active: false,
    },
  ];

  return (
    <div style={{ padding: "20px 20px 20px 20px" }}>
      <div className="card card-st-1 mb-4">
        <div className="card-body pt-4 pb-4">
          <div className="d-flex align-items-center mb-2">
            <div className="tc-8 fs-18 fw-bold px-3 mb-3 me-auto">
              การชำระเงิน
            </div>
          </div>

          <div className="d-flex px-3 flex-wrap mb-2">
            {paymenttypeArray.map((item) => {
              return (
                <div
                  className="selectpayment mb-3 me-3"
                  key={`payment-${item.id}`}
                >
                  <input
                    type="radio"
                    className="hide-input"
                    name="selectpayment"
                    id={`type${item.id}`}
                    checked={paymenttype == item.id}
                    onChange={(e) => setPaymenttype(item.id)}
                    disabled={!item.active}
                  />
                  <label
                    htmlFor={`type${item.id}`}
                    className={
                      item.active ? "" : "bg-gray-200 cursor-not-allowed"
                    }
                  >
                    <div className={`icon icon-${item.id}`}></div>
                    <div>{item.name}</div>
                  </label>
                </div>
              );
            })}
          </div>

          {paymenttype > 0 ? (
            <div className="px-3">
              <div className="card card-st-1 mb-4">
                <div className="card-body p-4">
                  {paymenttype === 1 ? <Cashpaymentsetting /> : ""}

                  {paymenttype === 2 ? <QRpaymentsetting /> : ""}

                  {paymenttype === 3 ? <Transferpaymentsetting /> : ""}

                  {paymenttype === 4 ? <Deliverypaymentsetting /> : ""}

                  {paymenttype === 5 ? <Creditpaymentsetting /> : ""}

                  {paymenttype === 6 ? <Debtorspaymentsetting /> : ""}

                  {paymenttype === 7 ? <Pointpaymentsetting /> : ""}

                  {paymenttype === 8 ? <Otherpaymentsetting /> : ""}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="py-5"></div>
        </div>
      </div>
    </div>
  );
}
