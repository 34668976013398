import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import useToken from "utils/UseToken";
import transferApi from "services/business/payment/transferApi";

// Bank type with logo
const bankType = [
  { value: 1, label: "ธนาคารกสิกรไทย", logo: "/images/payment/bank/kbank.png" },
  { value: 2, label: "ธนาคารไทยพาณิชย์", logo: "/images/payment/bank/scb.png" },
  { value: 3, label: "ธนาคารกรุงศรีอยุธยา", logo: "/images/payment/bank/krungsri.png" },
  { value: 5, label: "ธนาคารกรุงเทพ", logo: "/images/payment/bank/bangkok.png" },
  { value: 6, label: "ธนาคารกรุงไทย", logo: "/images/payment/bank/ktb.png" },
];

export default function Transferpaymentsetting() {
  const { branchID } = useParams();
  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const [bank, setBank] = useState([]);

  const config = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
        "business-id": user.businessId,
      },
    }),
    [token, user.businessId]
  );

  const configCreate = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "business-id": user.businessId,
      },
    }),
    [token, user.businessId]
  );

  const getAllTransfer = async (branchID) => {
    try {
      const res = await transferApi.GetTransfer(branchID, config);
      const data = res.data.map((item) => {
        const bankTypeData = bankType.find((bank) => bank.label === item.bank_title);
        return {
          bankID: item.id,
          bankLogo: bankTypeData?.logo, // Optional chaining for undefined scenarios
          bankName: item.bank_title,
          bankAccountName: item.bank_account_name,
          bankAccountNumber: item.bank_account_number,
          bankAccountBranch: item.bank_account_branch,
          status: item.is_active,
        };
      });
      setBank(data);
    } catch (error) {
      console.error("Failed to fetch transfer data:", error);
      // Optionally, show user feedback via toast or modal
    }
  };

  const createTransfer = async (data) => {
    try {
      const res = await transferApi.CreteTransfer(branchID, data, configCreate);
      // console.log(res);
    } catch (error) {
      console.error("Failed to create transfer data:", error);
    }
  };

  const updateTransfer = async (data) => {
    try {
      const res = await transferApi.UpdateTransfer(branchID, data.id, data, configCreate);
      // console.log(res);
    } catch (error) {
      console.error("Failed to update transfer data:", error);
    }
  };

  useEffect(() => {
    if (user.businessId && token) {
      getAllTransfer(branchID);
    }
  }, [user.businessId, token]);

  const [paymentsetting, setPaymentsetting] = useState({
    branchID: branchID,
    paymentChannelID: 3,
    status: 1,
  });

  const handleSubmit = () => {
    createTransfer(paymentsetting);
  };

  const [formdata, setFormdata] = useState();
  const [modalcreateShow, setModalcreateShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);

  return (
    <div>
      <div className="mb-4">
        <Form.Check
          type="switch"
          id="status"
          label="จัดการข้อมูลการโอนเงิน"
          name="status"
          className="custom-switch-st-1 tc-10  s1 mb-1"
          defaultChecked={paymentsetting.status === 1}
          onChange={(e) => {
            if (e.target.checked === true) {
              setPaymentsetting((prev) => ({ ...prev, status: 1 }));
            } else {
              setPaymentsetting((prev) => ({ ...prev, status: 0 }));
            }
          }}
        />
      </div>

      {paymentsetting.status === 1 ? (
        <>
          <Button
            type="button"
            className="btn btn btn-st-7 icon-plus mb-4"
            onClick={() => {
              setModalcreateShow(true);
              setFormdata({
                id: null,
                bankID: "",
                bankAccountName: "",
                bankAccountNumber: "",
                bankAccountBranch: "",
                status: 1,
              });
            }}
          >
            เพิ่มบัญชีธนาคาร
          </Button>

          <div className="d-flex flex-wrap">
            {bank.map((item) => (
              <div
                className={
                  item.status === 0
                    ? "bankStatus0 d-flex align-items-center px-3 py-2 me-3 mb-3"
                    : "d-flex align-items-center px-3 py-2 me-3 mb-3"
                }
                key={item.bankID}
                style={{
                  minWidth: "283px",
                  borderRadius: "5px",
                  border: "1px solid rgba(76, 78, 100, 0.22)",
                }}
              >
                <div className="logo-bank me-3">
                  <img
                    src={item.bankLogo}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="flex-fill">
                  <div style={{ color: " #2C2C2C" }}>
                    {item.bankAccountName}
                  </div>
                  <div className="fs-14 fw-light" style={{ color: "#6D788D" }}>
                    {item.bankAccountNumber}
                  </div>
                </div>
                <div className="ms-2">
                  <button
                    type="button"
                    className="btn-edit-st-1"
                    onClick={() => {
                      setModalcreateShow(true);
                      setFormdata(item);
                    }}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        ""
      )}

      <div className="text-end mt-4  pt-5 pb-2 btn-group-control">
        <Button
          type="submit"
          className="btn-st-3  "
          style={{ width: "100px" }}
          onClick={handleSubmit}
        >
          {" "}
          บันทึก
        </Button>
      </div>

      <BankModal
        show={modalcreateShow}
        onHide={() => setModalcreateShow(false)}
        handleDelete={() => {
          setModalDeleteShow(true);
          setModalcreateShow(false);
        }}
        onSuccess={() => {
          setModalcreateShow(false);
          getAllTransfer(branchID);
        }}
        formdata={formdata}
        createTransfer={createTransfer}
        updateTransfer={updateTransfer}
      />

      <ConfirmDeleteModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onConfirm={() => setModalDeleteShow(false)}
        onSuccess={() => {
          setModalsuccessShow(true);
          getAllTransfer(branchID);
        }}
        formdata={formdata}
        branchID={branchID}  // Pass branchID as a prop
        config={config}  // Pass config as a prop for deletion
      />
      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
      />
    </div>
  );
}

function BankModal(props) {
  const { setModalDeleteShow, formdata, createTransfer, updateTransfer, ...rest } = props;

  const schema = yup.object().shape({
    bankName: yup.string().required("กรุณาเลือกธนาคาร"),
    bankAccountName: yup.string().required("กรุณากรอกชื่อบัญชีธนาคาร"),
    bankAccountNumber: yup
      .string()
      .matches(/^[0-9]+$/, "เลขบัญชีธนาคารต้องเป็นตัวเลข")
      .required("กรุณากรอกเลขบัญชีธนาคาร"),
  });

  const handleSubmit = async (values) => {
    const data = {
      name: "Transfer",  
      bank_account_branch: values.bankAccountBranch,
      bank_account_name: values.bankAccountName,
      bank_account_number: values.bankAccountNumber,
      bank_title: values.bankName,
      is_active: values.status ? true : false, 
      fee_type: false, 
    };

    const dataUpdate = {
      id: values.bankID,
      bank_account_branch: values.bankAccountBranch,
      bank_account_name: values.bankAccountName,
      bank_account_number: values.bankAccountNumber,
      bank_title: values.bankName,
      is_active: values.status ? true : false, 
      fee_type: false, 
    };
    try {
      if (values.id !== null) {
        await updateTransfer(dataUpdate);
      } else {
        await createTransfer(data);
      }
      props.onSuccess();
    } catch (error) {
      console.error("Error handling transfer:", error);
    }
  };
  const optionsBank = [
    { value: 1, label: "ธนาคารกสิกรไทย" },
    { value: 2, label: "ธนาคารไทยพาณิชย์" },
    { value: 3, label: "ธนาคารกรุงศรีอยุธยา" },
    // { value: 4, label: 'ทหารไทย' },
    { value: 5, label: "ธนาคารกรุงเทพ" },
    { value: 6, label: "ธนาคารกรุงไทย" },
    // { value: 7, label: 'เกียรตินาคิน' },
    // { value: 8, label: 'ซีไอเอ็มบีไทย' },
    // { value: 9, label: 'ทิสโก้' },
    // { value: 10, label: 'ธนชาต' },
    // { value: 11, label: 'ยูโอบี' },
    // { value: 12, label: 'สแตนดาร์ดชาร์เตอร์ด (ไทย)' },
    // { value: 13, label: 'ไทยเครดิตเพื่อรายย่อย' },
    // { value: 14, label: 'ออมสิน' },
    // { value: 15, label: 'อาคารสงเคราะห์' },
    // { value: 16, label: 'เพื่อการเกษตรและสหกรณ์การเกษตร' },
    // { value: 17, label: 'พัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย' },
    // { value: 18, label: 'ไอซีบีซี (ไทย)' },
    // { value: 19, label: 'เพื่อการส่งออกและนำเข้าแห่งประเทศไทย' },
    // { value: 20, label: 'แห่งประเทศจีน' },
    // { value: 21, label: 'ซูมิโตโม มิตซุย ทรัสต์ (ไทย)' },
    // { value: 22, label: 'อิสลามแห่งประเทศไทย' },
    // { value: 23, label: 'ฮ่องกงและเซี้ยงไฮ้แบงกิ้งคอร์ปอเรชั่น จำกัด' }
  ];
  return (
    <Modal {...rest} size="md" centered>
      <Modal.Body>
        <div className="d-flex align-items-center px-3 py-2 mb-4 pb-3">
          <div className="fs-24 fw-bold me-auto" style={{ color: "#565DFF" }}>
            จัดการบัญชีธนาคาร
          </div>
          <button
            onClick={props.onHide}
            className="btn btn-close-modal"
          ></button>
        </div>
        <div className="px-3">
          <Formik
            enableReinitialize
            initialValues={props.formdata}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isInvalid,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ธนาคาร <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    as="select"
                    name="bankName"
                    className={
                      errors.bank
                        ? "w-100 invalid select-st-1"
                        : "w-100 cc select-st-1"
                    }
                    options={optionsBank}
                    placeholder="เลือกธนาคาร"
                    onChange={(e) => {
                      setFieldValue("bankName", e.label);
                    }}
                    value={optionsBank.filter(
                      (item) => item.label === values.bankName
                    )}
                  />
                </Form.Group>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อบัญชีธนาคาร <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccountName"
                    className="form-control"
                    placeholder="กรอกชื่อบัญชีธนาคาร"
                    value={values.bankAccountName}
                    onChange={handleChange}
                    isInvalid={!!errors.bankAccountName}
                  />
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    เลขบัญชีธนาคาร <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccountNumber"
                    className="form-control"
                    placeholder="กรอกเลขบัญชีธนาคาร"
                    value={values.bankAccountNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.bankAccountNumber}
                  />
                </Form.Group>

                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">สาขา </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccountBranch"
                    className="form-control"
                    placeholder="กรอกสาขา"
                    value={values.bankAccountBranch}
                    onChange={handleChange}
                    isInvalid={!!errors.bankAccountBranch}
                  />
                </Form.Group>

                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="เปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-21`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === true}
                      onChange={(e) => {
                        setFieldValue("status", true);
                      }}
                    />
                    <Form.Check
                      inline
                      label="ปิดใช้งาน"
                      name="status"
                      type={type}
                      id={`inline-${type}-22`}
                      className="form-check-st-2 tc-7"
                      checked={values.status === false}
                      onChange={(e) => {
                        setFieldValue("status", false);
                      }}
                    />
                  </div>
                ))}

                <div className="d-flex align-items-center pt-2 pb-3 btn-group-control">
                  {values.id !== null ? (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto px-4 "
                      style={{ width: "90px" }}
                      onClick={props.handleDelete}
                    >
                      ลบ
                    </Button>
                  ) : (
                    <div className="me-auto"></div>
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3 "
                    style={{ width: "100px" }}
                    onClick={props.onHide}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3  "
                    style={{ width: "100px" }}
                  >
                    {values.bankID ? "แก้ไข" : "บันทึก"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ConfirmDeleteModal(props) {
  const handleDelete = async (bankID) => {
    try {
      await transferApi.DeleteTransfer(props.branchID, bankID, props.config); // Pass branchID, bankID, and config

      props.onHide();
      props.onSuccess();
    } catch (error) {
      console.error("Delete failed", error);
    }
  };
  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        <div className="px-3 py-4">
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">รายการนี้ใช่หรือไม่</div>
            </div>

            <div className="text-center pt-4 mt-3  btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3  "
                style={{ width: "100px" }}
                onClick={() => handleDelete(props.formdata.bankID)}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3 "
                style={{ width: "100px" }}
                onClick={props.onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SuccessDeleteModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body>
      <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4">
                    <div className="mb-4">
                        <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
                            <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="text-center fs-22 tc-7">ทำรายการสำเร็จ</div>
                </div>
      </Modal.Body>
    </Modal>
  );
}