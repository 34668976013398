const data = [
    {
        id: 1,
        name: 'สินค้าสำเร็จรูป'
    },
    {
        id: 2,
        name: 'วัตถุดิบ'
    },
    {
        id: 3,
        name: 'อาหาร/เครื่องดื่ม'
    },
    {
        id: 4,
        name: 'บริการ'
    },
    {
        id: 5,
        name: 'อื่นๆ'
    }
];

export default data;