import axios from "axios";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const CreateCredit = (branchId, data, config) =>
    axiosClient.post(`/payment-Debit/${branchId}`, data, config);

  const GetCredit = (branchId, config) =>
    axiosClient.get(`/payment-Debit/${branchId}`, config);

  const GetCreditById = (branchId, id, config) =>
    axiosClient.get(`/payment-Debit/${branchId}/${id}`, config);
    
  const UpdateCredit = (branchId, id, data, config) =>
    axiosClient.patch(`/payment-Debit/${branchId}/${id}`, data, config);

  const DeleteCredit = (branchId, id, config) =>
    axiosClient.delete(`/payment-Debit/${branchId}/${id}`, config);

export default {
    CreateCredit,
    GetCredit,
    GetCreditById,
    UpdateCredit,
    DeleteCredit
};