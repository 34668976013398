import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import useToken from "utils/UseToken";
import deliveryApi from "services/business/payment/deliveryApi";
import UploadFile from "utils/UploadFile";

// Define the Upload URL
const UploadImageFileURL = "https://your-upload-url.com"; // Replace with your actual URL

// Function to check if a URL is valid
const checkURL = (url) => {
    try {
        new URL(url);
        return true;
    } catch (_) {
        return false;
    }
};

export default function DeliveryPaymentSetting() {
    const { branchID } = useParams();
    const { token } = useToken();
    const { user } = useRecoilValue(authState);
    const [delivery, setDelivery] = useState([]);

    const config = useMemo(() => ({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
            "business-id": user.businessId,
        },
    }), [token, user.businessId]);

    const configCreate = useMemo(() => ({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "business-id": user.businessId,
        },
    }), [token, user.businessId]);

    const getAllDelivery = async (branchID) => {
        try {
            const res = await deliveryApi.GetDelivery(branchID, config);
            const data = res.data.map((item) => ({
                deliveryID: item.id,
                deliveryName: item.delivery_name,
                deliveryLogo: item.delivery_image,
                status: item.is_active,
            }));
            setDelivery(data);
        } catch (error) {
            console.log("Failed to fetch delivery data:", error);
        }
    };

    const updateDelivery = async (data) => {
        try {
            const res = await deliveryApi.UpdateDelivery(branchID, data.id, data, configCreate);
            // console.log(res);
        } catch (error) {
            console.log("Failed to update delivery data:", error);
        }
    };

    const createDelivery = async (data) => {
        try {
            const res = await deliveryApi.CreateDelivery(branchID, data, configCreate);
            // console.log(res);
        } catch (error) {
            console.log("Failed to create delivery data:", error);
        }
    };

    useEffect(() => {
        if (user.businessId && token) {
            getAllDelivery(branchID);
        }
    }, [user.branchId, token]);

    const [paymentSetting, setPaymentSetting] = useState({
        branchID: branchID,
        paymentChannelID: 4,
        status: 1
    });

    const handleSubmit = () => {
        console.log(paymentSetting);
    };

    const [formData, setFormData] = useState();
    const [modalCreateShow, setModalCreateShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);

    return (
        <div>
            <div className='mb-4'>
                <Form.Check
                    type="switch"
                    id="status"
                    label='ตั้งค่าเดลิเวอรี่'
                    name='status'
                    className='custom-switch-st-1 tc-10 s1 mb-1'
                    defaultChecked={paymentSetting.status === 1}
                    onChange={(e) => {
                        setPaymentSetting((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }));
                    }}
                />
            </div>

            {paymentSetting.status === 1 && (
                <>
                    <Button
                        type='button'
                        className='btn btn-st-7 icon-plus mb-4'
                        onClick={() => {
                            setModalCreateShow(true);
                            setFormData({
                                id: null,
                                deliveryID: "",
                                deliveryName: "",
                                deliveryLogo: "",
                                status: false
                            });
                        }}>
                        เพิ่มเดลิเวอรี่
                    </Button>

                    <div className='d-flex flex-wrap'>
                        {delivery.map((item, index) => (
                            <div
                                className={item.status === 0 ? 'deliveryStatus0 d-flex align-items-center px-3 py-2 me-3 mb-3' : 'd-flex align-items-center px-3 py-2 me-3 mb-3'}
                                key={item.deliveryID}
                                style={{ minWidth: '283px', borderRadius: '5px', border: '1px solid rgba(76, 78, 100, 0.22)' }}>
                                <div className='logo-delivery me-3'>
                                    <img src={item.deliveryLogo} style={{ width: '30px', height: '30px', borderRadius: '15%', objectFit: 'cover' }} />
                                </div>
                                <div className='flex-fill'>
                                    <div style={{ color: '#2C2C2C' }}>{item.deliveryName}</div>
                                </div>
                                <div className='ms-2'>
                                    <button type='button' className='btn-edit-st-1' onClick={() => { setModalCreateShow(true); setFormData(item); }}></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            <div className="text-end mt-4 pt-5 pb-2 btn-group-control">
                <Button type="submit" className='btn-st-3' style={{ width: '100px' }} onClick={handleSubmit}> บันทึก</Button>
            </div>

            <DeliveryModal
                show={modalCreateShow}
                onHide={() => setModalCreateShow(false)}
                handleDelete={() => {
                    setModalDeleteShow(true);
                    setModalCreateShow(false);
                }}
                onSuccess={() => {
                    setModalCreateShow(false);
                    getAllDelivery(branchID);
                }}
                formData={formData}
                createDelivery={createDelivery}
                updateDelivery={updateDelivery}
            />

            <ConfirmDeleteModal
                show={modalDeleteShow}
                onHide={() => setModalDeleteShow(false)}
                onConfirm={() => setModalDeleteShow(false)}
                onSuccess={() => {
                    setModalSuccessShow(true);
                    getAllDelivery(branchID);
                }}
                formData={formData}
                branchID={branchID}
                config={config}
            />

            <SuccessDeleteModal
                show={modalSuccessShow}
                onHide={() => setModalSuccessShow(false)}
            />
        </div>
    );
}

function DeliveryModal(props) {
    const { setModalDeleteShow, formData, handleDelete, onSuccess, ...rest } = props;

    const schema = yup.object().shape({
        deliveryName: yup.string().required(),
    });

    const handleSubmit = async (values) => {
        const data = {
            name: "Delivery",
            delivery_name: values.deliveryName,
            delivery_image: values.deliveryLogo,
            is_active: values.status,
            fee_type: false,
        };

        const dataUpdate = {
            id: values.deliveryID,
            ...data,
        };

        try {
            if (values.deliveryID) {
                await props.updateDelivery(dataUpdate);
            } else {
                await props.createDelivery(data);
            }
            props.onSuccess();
        } catch (error) {
            console.log("Failed to create delivery data:", error);
        }
    };

    const uploadImageFile = async (file, token, user) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await fetch(UploadImageFileURL, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    "business-id": user.businessId,
                }),
                body: formData,
            });

            return await response.text();
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    return (
        <Modal {...rest} size="md" centered>
            <Modal.Body>
                <div className='d-flex align-items-center px-3 py-2 mb-4 pb-3'>
                    <div className='fs-24 fw-bold me-auto' style={{ color: '#565DFF' }}>ตั้งค่าเดลิเวอรี่</div>
                    <button onClick={props.onHide} className='btn btn-close-modal'></button>
                </div>
                <div className='px-3'>
                    <Formik
                        enableReinitialize
                        initialValues={props.formData}
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            setFieldValue,
                            errors,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 form-st-3 pb-1">
                                    <Form.Label className="mb-1">
                                        ชื่อช่องทาง <span className='text-danger'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="deliveryName"
                                        className="form-control"
                                        placeholder="กรอกชื่อช่องทาง"
                                        value={values.deliveryName}
                                        onChange={handleChange}
                                        isInvalid={!!errors.deliveryName}
                                    />
                                </Form.Group>

                                <div className='pt-1 pb-2'>
                                    <Imageupload
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        uploadImageFile={uploadImageFile}
                                    />
                                </div>

                                <Form.Label className="mb-2  tc-10">สถานะ</Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="เปิดใช้งาน"
                                            name="status"
                                            type={type}
                                            id={`inline-${type}-21`}
                                            className='form-check-st-2 tc-7'
                                            checked={values.status === true}
                                            onChange={() => {
                                                setFieldValue("status", true);
                                            }}
                                        />
                                        <Form.Check
                                            inline
                                            label="ปิดใช้งาน"
                                            name="status"
                                            type={type}
                                            id={`inline-${type}-22`}
                                            className='form-check-st-2 tc-7'
                                            checked={values.status === false}
                                            onChange={() => {
                                                setFieldValue("status", false);
                                            }}
                                        />
                                    </div>
                                ))}

                                <div className="d-flex align-items-center pt-5 pb-3 btn-group-control">
                                    {values.id !== null ? (
                                        <Button type="button" className='btn-st-5 me-auto px-4' style={{ width: "90px" }} onClick={props.handleDelete}>ลบ</Button>
                                    ) : <div className='me-auto'></div>}
                                    <Button type="button" className='btn-st-6 me-3' style={{ width: '100px' }} onClick={props.onHide}>ยกเลิก</Button>
                                    <Button type="submit" className='btn-st-3' style={{ width: '100px' }}>{values.deliveryID ? 'แก้ไข' : 'บันทึก'}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function ConfirmDeleteModal(props) {
    const handleDelete = async (deliveryID) => {
        try {
            await deliveryApi.DeleteDelivery(props.branchID, deliveryID, props.config);
            props.onHide();
            props.onSuccess();
        } catch (error) {
            console.log("Failed to delete delivery data:", error);
        }
    };

    return (
        <Modal {...props} size="md" centered>
            <Modal.Body>
                <div className='px-3 py-4'>
                    <div>
                        <div className='text-center fs-22 tc-7 mb-2'>คุณต้องการลบ</div>
                        <div className='text-center fs-22 tc-7'>รายการนี้ใช่หรือไม่</div>
                    </div>
                    <div className="text-center pt-4 mt-3 btn-group-control">
                        <Button type="button" className='btn-st-3 mx-3' style={{ width: '100px' }} onClick={() => handleDelete(props.formData.deliveryID)}>ใช่</Button>
                        <Button type="button" className='btn-st-6 mx-3' style={{ width: '100px' }} onClick={props.onHide}>ไม่ใช่</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function SuccessDeleteModal(props) {
    return (
        <Modal {...props} size="sm" centered>
            <Modal.Body>
                <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4">
                    <div className="mb-4">
                        <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
                            <path d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="text-center fs-22 tc-7">ทำรายการสำเร็จ</div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const Imageupload = (props) => {
    const [imageURL, setImageURL] = useState(
        props.values.img_url !== "" ? props.values.img_url : undefined
    );
    const [imgData, setImgData] = useState(false);
    const [loading, setLoading] = useState(false);

    const handlePreviewImage = async (e) => {
        setLoading(true);
        if (e.target.files[0]) {
            try {
                const res = await UploadFile("branch-info-images", e.target.files[0]);
                setImageURL(res);
                props.setFieldValue("deliveryLogo", res);
            } catch (error) {
                console.log("Failed to upload image:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setImgData(props.values.deliveryLogo);
        const exists = checkURL(props.values.img_url);
        const img_url = exists ? props.values.img_url : `${process.env.REACT_APP_API_URL}${props.values.img_url}`;
        setImageURL(img_url);
    }, [props.values]);

    return (
        <div className=''>
            <div className="d-flex align-items-center mb-4 mt-3">
                <div className="bx-upload">
                    {imgData ? (
                        <img src={imgData} alt="Delivery Logo" />
                    ) : (
                        <div className='preimg'></div>
                    )}
                </div>
                <input type="file" className="fileUpload hide-input" id='fileUpload' accept="image/*" onChange={handlePreviewImage} />

                <div className="flex-fill ms-3">
                    <div className="mb-3">
                        <button type='button' className="btn btn-st-4 btn-upload me-3" onClick={() => document.getElementById('fileUpload').click()}>
                            {loading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <span>อัพโหลดรูปภาพ</span>
                            )}
                        </button>
                        <button type='button' className="btn btn-st-2 btn-remove-image" onClick={() => { setImgData(false); props.setFieldValue("deliveryLogo", '') }} style={{ width: '70px' }}>ลบ</button>
                    </div>
                    <div className="tc-7 fs-12">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                </div>
            </div>
        </div>
    );
};
