import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { utils, writeFileXLSX } from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Button from "react-bootstrap/Button";
import useToken from "utils/UseToken";
import { useRecoilValue } from "recoil";
import { appState } from "../../state/app";
import { authState } from "../../state/auth";
import pos2 from "../../img/pos2.png";
import { MoonLoader } from "react-spinners";
import {
  getCurrentDate,
  convertDateFormat,
  getCurrentDateM,
  formatDateToYMD,
  subtractDays,
  getCurrentDateforApi,
} from "utils/dateFormat";
import Select from "react-select";
import DailyApi from "services/report/DailyApi";

export function ReportSales() {
  const { token, setToken } = useToken();
  let { businessId } = useParams();
  const navigate = useNavigate();
  const { slug } = useRecoilValue(appState);
  const { user } = useRecoilValue(authState);
  const BASE_URL =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard/total";
  const BASE_URL_POS =
    process.env.REACT_APP_API_URL + "/back-office-info-dashboard";

  const [business, setBusiness] = useState(businessId);
  const [reportdata, setreportdata] = useState([]);
  const [searchdata, setSearchdata] = useState({
    branchID: "",
    posID: "",
    startDate: getCurrentDate("/"),
    endDate: getCurrentDate("/"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showResetSearch, setShowResetSearch] = useState(false);
  const [pos, setPos] = useState([]);
  const [dateApplied, setDateApplied] = useState(false);
  const currentDate = new Date();
  const endDate = getCurrentDateforApi("-", currentDate);
  const startDate = getCurrentDateforApi("-", subtractDays(currentDate, 7));
  const [fetchDate, setCurrentDate] = useState({ startDate, endDate });
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsPos, setOptionsPos] = useState([]);
  const keyRef = useRef();

  const getReportPos = fetch(`${BASE_URL_POS}`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "business-id": businessId,
    }),
    body: JSON.stringify(fetchDate),
  }).then((response) => response.json());

  const handleClickPrintSummary = (summaryType, selectDate) => {
    const newselectDate = new Date(selectDate);
    const dateString = newselectDate.toISOString().split("T")[0];
    navigate(
      `/${slug}/report/sales/${user.businessId}/close-balance-print?summary-type=${summaryType}&date=${dateString}`
    );
  };


//   useEffect(() => {
//     // Create an AbortController for managing the requests
//     setIsLoading(true);
//     const abortController = new AbortController();
//     const signal = abortController.signal;

//     const fetchUsingAsyncAwaitWithFetchApi = async () => {
//       Promise.all([getReportPos])
//         .then(([dataPos]) => {
//           setreportdata(dataPos);
//           setPos(dataPos);
//           setError(null);
//         })
//         .catch((error) => {
//           if (error.name === "AbortError") {
//             console.log("Fetch aborted"); // Log a message when the request is intentionally aborted
//             return; // Exit the function to prevent further error handling
//           }
//           setError(error.message);
//         })
//         .finally(() => {
//           setIsLoading(false);
//         });
//     };

//     fetchUsingAsyncAwaitWithFetchApi();

//     // Cleanup: Abort the controller and set loading to true when the component unmounts
//     return () => {
//       abortController.abort(); // Cancel any ongoing requests
//       setIsLoading(true); // Reset loading state
//     };
//     // getreportData();
//   }, [business]);

  const columns = [
    {
      name: "วันที่ POS",
      selector: (row) => row.date,
      width: "110px",
      cell: (row, index, column, id) => {
        // format date to dd/mm/yyyy
        const date = new Date(row.date);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return <div>{`${day}/${month}/${year}`}</div>;
      },
    },
    {
      name: "#รอบการขาย",
      selector: (row) => row.round_of_sale,
      width: "120px",
    },
    {
      name: "ยอดรวม",
      selector: (row) => row.total_sale,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.total_sale ? formatter.format(row.total_sale) : "0.00"}
          </div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm">
          <div>ส่วนลด</div>
          <div>ITEM</div>
        </div>
      ),
      selector: (row) => row.discount,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>{row.discount ? formatter.format(row.discount) : "0.00"}</div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm">
          <div>ส่วนลด</div>
          <div>ท้ายบิล</div>
        </div>
      ),
      selector: (row) => row.discount_end_bill,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.discount_end_bill
              ? formatter.format(row.discount_end_bill)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm">
          <div>ส่วนลด</div>
          <div>คะแนน</div>
        </div>
      ),
      selector: (row) => row.discount_from_point,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.discount_from_point
              ? formatter.format(row.discount_from_point)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: "ยอดสุทธิ",
      selector: (row) => row.net_balance,
      width: "140px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div className="text-[--color-ligth-purple] font-bold">
            {formatter.format(row.net_balance)}
          </div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm flex flex-col justify-center items-center">
          <div>มูลค่าสินค้า</div>
          <div className="fs-10">ไม่มีภาษี</div>
        </div>
      ),
      selector: (row) => row.product_price_no_vat,
      width: "140px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.product_price_no_vat
              ? formatter.format(row.product_price_no_vat)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm flex flex-col justify-center items-center">
          <div>มูลค่าสินค้า</div>
          <div className="fs-10">มีภาษี</div>
        </div>
      ),
      selector: (row) => row.product_price_vat,
      width: "140px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.product_price_vat
              ? formatter.format(row.product_price_vat)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: "ภาษีมูลค่าเพิ่ม",
      selector: (row) => row.vat,
      width: "120px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return <div>{formatter.format(row.vat)}</div>;
      },
    },
    {
      name: "จำนวนบิล",
      selector: (row) => row.number_of_bill,
      width: "120px",
    },
    {
      name: (
        <div className="lh-sm flex flex-col justify-center items-center">
          <div>จำนวนบิล</div>
          <div className="fs-10">ที่ยกเลิก</div>
        </div>
      ),
      selector: (row) => row.number_of_bill_void,
      width: "120px",
    },
    {
      name: (
        <div className="lh-sm text-red-500 flex flex-col justify-center items-center">
          <div>จำนวนบิล</div>
          <div className="fs-10">การคืนสินค้า</div>
        </div>
      ),
      selector: (row) => row.refund_bill,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div className="text-red-500">
            {row.refund_bill ? formatter.format(row.refund_bill) : "0.00"}
          </div>
        );
      },
      width: "130px",
    },
    {
      name: (
        <div className="lh-sm text-red-500 flex flex-col justify-center items-center">
          <div>มูลค่าสินค้า</div>
          <div className="fs-10">การคืนสินค้า</div>
        </div>
      ),
      selector: (row) => row.refund_value,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div className="text-red-500">
            {row.refund_value ? formatter.format(row.refund_value) : "0.00"}
          </div>
        );
      },
      width: "140px",
    },
    {
      name: (
        <div className="w-full lh-sm flex justify-center items-center">
          พิมพ์
        </div>
      ),
      selector: (row) => row.inventories,
      width: "180px",
      cell: (row, index, column, id) => (
        <div>
          <Button
            type="button"
            onClick={() => handleClickPrintSummary("by-date", row.date)}
            className=""
            style={{
              color: "#565DFF",
              background: "inherit",
              border: "1px solid #565DFF",
            }}
          >
            พิมพ์สรุปรวมสิ้นวัน
          </Button>
        </div>
      ),
    },
  ];

  const columnsPos = [
    {
      name: "POS",
      selector: (row) => row.pos_id,
      width: "110px",
      cell: (row, index, column, id) => {
        return (
          <div className="flex justify-start items-center gap-1">
            <img src={pos2} alt="pos-icon" className="w-[19px]" />
            POS {row.pos_id}
          </div>
        );
      },
    },
    {
      name: "#รอบการขาย",
      selector: (row) => row.round_of_sale,
      width: "120px",
    },
    {
      name: "ยอดรวม",
      selector: (row) => row.total_sale,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.total_sale ? formatter.format(row.total_sale) : "0.00"}
          </div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm">
          <div>ส่วนลด</div>
          <div>ITEM</div>
        </div>
      ),
      selector: (row) => row.discount,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>{row.discount ? formatter.format(row.discount) : "0.00"}</div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm">
          <div>ส่วนลด</div>
          <div>ท้ายบิล</div>
        </div>
      ),
      selector: (row) => row.discount_end_bill,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.discount_end_bill
              ? formatter.format(row.discount_end_bill)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm">
          <div>ส่วนลด</div>
          <div>คะแนน</div>
        </div>
      ),
      selector: (row) => row.discount_from_point,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.discount_from_point
              ? formatter.format(row.discount_from_point)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: "ยอดสุทธิ",
      selector: (row) => row.net_balance,
      width: "140px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return <div>{formatter.format(row.net_balance)}</div>;
      },
    },
    {
      name: (
        <div className="lh-sm flex flex-col justify-center items-center">
          <div>มูลค่าสินค้า</div>
          <div className="fs-10">ไม่มีภาษี</div>
        </div>
      ),
      selector: (row) => row.product_price_no_vat,
      width: "140px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.product_price_no_vat
              ? formatter.format(row.product_price_no_vat)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: (
        <div className="lh-sm flex flex-col justify-center items-center">
          <div>มูลค่าสินค้า</div>
          <div className="fs-10">มีภาษี</div>
        </div>
      ),
      selector: (row) => row.product_price_vat,
      width: "140px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div>
            {row.product_price_vat
              ? formatter.format(row.product_price_vat)
              : "0.00"}
          </div>
        );
      },
    },
    {
      name: "ภาษีมูลค่าเพิ่ม",
      selector: (row) => row.vat,
      width: "120px",
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return <div>{formatter.format(row.vat)}</div>;
      },
    },
    {
      name: "จำนวนบิล",
      selector: (row) => row.number_of_bill,
      width: "120px",
    },
    {
      name: (
        <div className="lh-sm flex flex-col justify-center items-center">
          <div>จำนวนบิล</div>
          <div className="fs-10">ที่ยกเลิก</div>
        </div>
      ),
      selector: (row) => row.number_of_bill_void,
      width: "120px",
    },
    {
      name: (
        <div className="lh-sm text-red-500 flex flex-col justify-center items-center">
          <div>จำนวนบิล</div>
          <div className="fs-10">การคืนสินค้า</div>
        </div>
      ),
      selector: (row) => row.refund_bill,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div className="text-red-500">
            {row.refund_bill ? formatter.format(row.refund_bill) : "0.00"}
          </div>
        );
      },
      width: "130px",
    },
    {
      name: (
        <div className="lh-sm text-red-500 flex flex-col justify-center items-center">
          <div>มูลค่าสินค้า</div>
          <div className="fs-10">การคืนสินค้า</div>
        </div>
      ),
      selector: (row) => row.refund_value,
      cell: (row, index, column, id) => {
        // format price 2 decimal with comma
        const formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return (
          <div className="text-red-500">
            {row.refund_value ? formatter.format(row.refund_value) : "0.00"}
          </div>
        );
      },
      width: "140px",
    },
    {
      name: "พิมพ์สรุปสิ้นวัน",
      selector: (row) => row.inventories,
      width: "180px",
      cell: (row, index, column, id) => (
        <div>
          <Button
            type="button"
            onClick={() => handleClickPrintSummary("by-pos", row.date)}
            className=""
            style={{
              width: "149.98px",
              color: "#565DFF",
              background: "#FFF",
              border: "1px solid #565DFF",
            }}
          >
            พิมพ์สรุปสิ้นวัน
          </Button>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        fontSize: "14px",
        background: " #F5F5F7",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontWeight: "500",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        background: " #E5E0E0",
        fontSize: "14px",
        fontWeight: "300",
      },
    },
    expanderRow: {
      style: {
        minHeight: "52px",
      },
    },
    pagination: {
      style: {
        borderBottomRightRadius: "0.75rem",
        borderBottomLeftRadius: "0.75rem",
      },
    },
  };

  const customStylesExpander = {
    rows: {
      style: {
        minHeight: "52px",
      },
    },
    cells: {
      style: {
        paddingLeft: "15px",
        paddingRight: "15px",
        color: "rgba(76, 78, 100, 0.87)",
        fontFamily: "Sarabun",
        background: " #FFFFFF",
        fontSize: "14px",
        fontWeight: "300",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "จำนวนรายการต่อหน้า",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const [filterText, setFilterText] = useState("");
  // const filteredItems = reportdata.filter(
  //   (item) =>
  //     item.posDate &&
  //     item.posDate.toLowerCase().includes(filterText.toLowerCase())
  // );

  const filteredItems = Array.isArray(reportdata)
    ? reportdata.filter(
        (item) =>
          item.date &&
          item.date.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];
  // console.log('filteredItems:', filteredItems)
  // console.log('report data', reportdata)

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(filteredItems);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "รายงานยอดขายรายวัน");
    writeFileXLSX(wb, "รายงานยอดขายรายวัน.xlsx");
  };

  const ExpandedComponent = ({ data }) => {
    const filterPos = pos.filter((item) => item.date === data.date);
    // getposData(data.posDate);
    return (
      <div className="hideexpandable">
        <DataTable
          columns={columnsPos}
          data={filterPos}
          noTableHead={true}
          expandableRows
          customStyles={customStylesExpander}
        />
      </div>
    );
  };
  const handleApply = (event, picker) => {
    setSearchdata((prev) => ({
      ...prev,
      startDate: picker.startDate.format("YYYY/MM/DD"),
    }));
    setSearchdata((prev) => ({
      ...prev,
      endDate: picker.endDate.format("YYYY/MM/DD"),
    }));
    setDateApplied(true);
  };

  const handleSearch = () => {
    setIsLoading(true);
    let formattedStartDate = searchdata.startDate;
    let formattedEndDate = searchdata.endDate;
    if (showResetSearch === false && dateApplied === false) {
      formattedStartDate = formatDateToYMD(searchdata.startDate);
      formattedEndDate = formatDateToYMD(searchdata.endDate);

      setSearchdata((prev) => ({
        ...prev,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));
    }

    const startDate = convertDateFormat(formattedStartDate);
    const endDate = convertDateFormat(formattedEndDate);
    const data = {
      startDate: startDate,
      endDate: endDate,
      branch_id: searchdata.branchID,
      pos_id: searchdata.posID
    };

    const formData = new URLSearchParams();
    formData.append("branch_id", searchdata.branchID);
    formData.append("pos_id", searchdata.posID);
    formData.append("startDate", formattedStartDate);
    formData.append("endDate", formattedEndDate);
    fetch(`${BASE_URL_POS}`, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "business-id": businessId,
      }),
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((dataPos) => {
        setreportdata(dataPos);
        setPos(dataPos);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setShowResetSearch(true);
        setIsLoading(false);
      });
  };

  const resetFilter = () => {
    // setSearchdata({
    //   branchID: "",
    //   posID: "",
    //   startDate: getCurrentDate("/"),
    //   endDate: getCurrentDate("/"),
    // });
    setShowResetSearch(false);
    // setDateApplied(false);
    setreportdata([]);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const getBranchData = async () => {
    DailyApi.GetAllBranchByBussiness(config).then((res) => {
      const data = res.data;
      const options = [];

      let obj = {};
      obj.value = "";
      obj.label = "ทั้งหมด";
      options.push(obj);

      data.map((item) => {
        let obj = {};
        obj.value = item.id;
        obj.label = item.name;
        options.push(obj);
      });

      setOptionsBranch(options);
    });
  };

  const getPosData = async (branchId) => {
    DailyApi.GetAllPosByBusiness(config, branchId).then((res) => {
      const data = res.data;
      const options = [];

      let obj = {};
      obj.value = "";
      obj.label = "ทั้งหมด";
      options.push(obj);

      data.map((item) => {
        let obj = {};
        obj.value = item.id;
        obj.label = item.name;
        options.push(obj);
      });

      setOptionsPos(options);
    });
  };

  useEffect(() => {
    if (user.businessId && token) {
      getBranchData();
    }
  }, [user.businessId, token]);

  const handlerSelectBranchChange = (e) => {
    // check if e is not null
    if (e) {
      if (e.label != "ทั้งหมด") {
        setSearchdata((prev) => ({ ...prev, branchID: e.value }));
        // filter pos by branch
        getPosData(e.value);
      } else {
        setSearchdata((prev) => ({ ...prev, branchID: "", posID: "" }));
        setOptionsPos([]);
      }
    }
  };

  const handlerSelectPosChange = (e) => {
    e.label != "ทั้งหมด"
      ? setSearchdata((prev) => ({ ...prev, posID: e.value }))
      : setSearchdata((prev) => ({ ...prev, posID: "" }));
  };

  return (
    <div>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              รายงาน
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            รายงานยอดขายรายวัน{" "}
          </li>
        </ol>
      </nav>

      <div className="card card-st-2 mb-4">
        <div className="card-body  py-4">
          <div className="fw-bold mb-3">ค้นหาขั้นสูง</div>
          <div className="row items-center">
            <div className="col-xl-2 col-lg-3 col-2">
              {/* <SelectBranch
                // handleChange={(e) => {
                //     console.log('handleChange', e)
                //     e.label != 'ทั้งหมด'
                //         ? setSearchdata((prev) => ({ ...prev, branchID: e.value }))
                //         : setSearchdata((prev) => ({ ...prev, branchID: '', posID: '' }))
                // }}
                handleChange={(e) => onSelectBranchChange(e)}
                sizeClass="pd"
                businessID={businessId}
              /> */}
              <Select
                as="select"
                name="branch"
                onChange={(e) => handlerSelectBranchChange(e)}
                className={`w-100 select-st-1 pd`}
                options={optionsBranch}
                placeholder="เลือกสาขา"
                isClearable={true}
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-2">
              {/* <SelectPos
                handleChange={(e) => {
                  e.label != "ทั้งหมด"
                    ? setSearchdata((prev) => ({ ...prev, posID: e.value }))
                    : setSearchdata((prev) => ({ ...prev, posID: "" }));
                }}
                businessID={businessId}
                isDisabled={searchdata.branchID.length === 0}
              /> */}
              <Select
                as="select"
                name="pos"
                onChange={(e) => handlerSelectPosChange(e)}
                className="w-100 select-st-1 pd"
                options={optionsPos}
                placeholder="เลือก POS"
                isDisabled={searchdata.branchID.length === 0}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-2">
              <DateRangePicker
                initialSettings={{
                  startDate: searchdata.startDate,
                  endDate: searchdata.endDate,
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                }}
                onApply={handleApply}
              >
                <input
                  type="text"
                  className="form-control iconcalendar-right"
                />
              </DateRangePicker>
            </div>
            <div className="col-auto">
              <Button
                type="button"
                className="btn-st-3 flex justify-center items-center"
                style={{ width: "120px", height: "40px" }}
                onClick={handleSearch}
              >
                ค้นหา
              </Button>
            </div>
            {showResetSearch && (
              <div className="col-auto">
                <Button
                  type="button"
                  className="bg-orange-400 flex justify-center items-center hover:bg-orange-300"
                  style={{ width: "150px", height: "40px" }}
                  onClick={resetFilter}
                >
                  ล้างการค้นหา
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="w-full h-screen flex justify-center items-start">
          <MoonLoader
            color="#565DFF"
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="mt-40"
          />
        </div>
      )}
      {showResetSearch && (
        <div className="card card-st-2">
          <div className="card-body p-0 rounded-xl">
            {/*<div className="d-flex align-items-center px-4 mb-4">*/}
            {/*  <button*/}
            {/*    className="btn btn-export-st-1 me-auto"*/}
            {/*    onClick={downloadExcel}*/}
            {/*  >*/}
            {/*    Export*/}
            {/*  </button>*/}
            {/*</div>*/}
            <DataTable
              columns={columns}
              data={filteredItems}
              customStyles={customStyles}
              // selectableRows
              // selectableRowsComponent={CheckboxTable}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
            />
          </div>
        </div>
      )}
    </div>
  );
}
