import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import useToken from "utils/UseToken";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import qrApi from "services/business/payment/qrApi";

export default function QRpaymentsetting() {
  const { token } = useToken();
  const { user } = useRecoilValue(authState);
  const { branchID } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const configCreate = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "business-id": user.businessId,
    },
  };

  const schema = yup.object().shape({
    qr_merchant_id: yup.string().when("is_active", (is_active, schema) => {
      if (is_active) return schema.required();
      return schema;
    }),
    qr_public_id: yup.string().when("is_active", (is_active, schema) => {
      if (is_active) return schema.required();
      return schema;
    }),
    qr_secret_key: yup.string().when("is_active", (is_active, schema) => {
      if (is_active) return schema.required();
      return schema;
    }),
    qr_token: yup.string().when("is_active", (is_active, schema) => {
      if (is_active) return schema.required();
      return schema;
    }),
  });

  const [paymentsetting, setPaymentsetting] = useState({
    branch_id: branchID,
    id: 0,
    is_active: false,
    qr_merchant_id: "",
    qr_public_id: "",
    qr_secret_key: "",
    qr_token: "",
  });

  useEffect(() => {
    if (user.businessId && token) {
      GetQr();
    }
  }, [user.businessId, token]);

  const GetQr = async () => {
    const res = await qrApi.GetQr(branchID, config);
    if (res.data) {
      //   console.log("🚀 ~ GetQr ~ res.data:", res.data);
      setPaymentsetting(res.data);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={paymentsetting}
        validationSchema={schema}
        onSubmit={async (values) => {
          console.log("🚀 ~ onSubmit={ ~ values:", values);
          //   console.log(values);
          try {
            const res = await qrApi.UpdateQr(
              branchID,
              paymentsetting.id,
              values,
              configCreate
            );
            console.log("🚀 ~ onSubmit ~ res", res);
          } catch (error) {
            console.log("🚀 ~ onSubmit ~ error", error);
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          isInvalid,
          isValid,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Check
                type="switch"
                id="is_active"
                label="ตั้งค่าระบบ QR (Auto Check)"
                name="is_active"
                className="custom-switch-st-1 tc-10  s1 mb-1"
                // defaultChecked={values.is_active}
                checked={values.is_active}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setFieldValue("is_active", true);
                  } else {
                    setFieldValue("is_active", false);
                  }
                }}
              />
            </div>
            {values.is_active === true ? (
              <>
                <div className="d-flex align-items-center mb-4 pb-2">
                  <img
                    src="/images/payment/gbpay.png"
                    className="me-4"
                    width={100}
                  />
                  <div
                    style={{
                      color: "rgba(76, 78, 100, 0.50)",
                      fontWeight: 500,
                    }}
                  >
                    <div className="mb-1">
                      กรุณากรอกข้อมูลที่ได้รับจากระบบ GB PAY
                    </div>
                    <div>หากไม่ทราบข้อมูลกรุณาติดต่อเจ้าหน้าที่</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Merchant ID <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="qr_merchant_id"
                        className="form-control"
                        placeholder="Merchant  ID"
                        value={values.qr_merchant_id}
                        onChange={handleChange}
                        isInvalid={!!errors.qr_merchant_id}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Public ID <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="qr_public_id"
                        className="form-control"
                        placeholder="Public ID"
                        value={values.qr_public_id}
                        onChange={handleChange}
                        isInvalid={!!errors.qr_public_id}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Secret Key ID <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="qr_secret_key"
                        className="form-control"
                        placeholder="Secret Key ID"
                        value={values.qr_secret_key}
                        onChange={handleChange}
                        isInvalid={!!errors.qr_secret_key}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-st-3 pb-1">
                      <Form.Label className="mb-1">
                        Token <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="qr_token"
                        className="form-control"
                        placeholder="Token"
                        value={values.qr_token}
                        onChange={handleChange}
                        isInvalid={!!errors.qr_token}
                      />
                    </Form.Group>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="text-end pt-2 pb-3 btn-group-control">
              <Button
                type="submit"
                className="btn-st-3  "
                style={{ width: "100px" }}
              >
                บันทึก
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
