import axios from "axios";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    });

    const CreateDelivery = (branchId, data, config) =>
        axiosClient.post(`/payment-delivery/${branchId}`, data, config);

    const GetDelivery = (branchId, config) =>
        axiosClient.get(`/payment-delivery/${branchId}`, config);

    const GetDeliveryById = (branchId, id, config) =>
        axiosClient.get(`/payment-delivery/${branchId}/${id}`, config);

    const UpdateDelivery = (branchId, id, data, config) =>
        axiosClient.patch(`/payment-delivery/${branchId}/${id}`, data, config);

    const DeleteDelivery = (branchId, id, config) =>
        axiosClient.delete(`/payment-delivery/${branchId}/${id}`, config);

export default {
    CreateDelivery,
    GetDelivery,
    GetDeliveryById,
    UpdateDelivery,
    DeleteDelivery
};