import React, { useState, useEffect } from "react";
import { utils, writeFileXLSX } from "xlsx";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import unitApi from "services/business/unitApi";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import useToken from "utils/UseToken";
import BusinesUnitTable from "components/BusinessUnit/list";
import CreateModal from "components/BusinessUnit/createModal";
import UnitEditModal from "components/BusinessUnit/editModal";
import ConfirmDeleteModal from "components/BusinessUnit/confirmDeleteModal";
import SuccessDeleteModal from "components/BusinessUnit/successDeleteModal";
import { getCurrentDate } from "utils/dateFormat";

export default function BusinesUnitListPage() {
  const { token } = useToken();
  const { user } = useRecoilValue(authState);

  const [unitdata, setUnitdata] = useState([]);

  const [modalunitShow, setModalunitShow] = useState(false);
  const [modaleditunitShow, setModaleditunitShow] = useState(false);
  const [modalunitDeleteShow, setModalunitDeleteShow] = useState(false);
  const [modalsuccessShow, setModalsuccessShow] = useState(false);

  const [uniteditdata, setUniteditdata] = useState({});

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "business-id": user.businessId,
    },
  };

  const configCreate = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "business-id": user.businessId,
    },
  };

  useEffect(() => {
    if (user.businessId && token) {
      GetAllUnit();
    }
  }, [user.businessId, token]);

  const GetAllUnit = async () => {
    const res = await unitApi.GetAllUnit(config);
    // console.log("🚀 ~ GetAllUnit ~ res", res.data);
    const data = res.data.map((item) => {
      return {
        unitID: item.id,
        unitCode: item.code,
        unitName: item.name,
        unitType: item.unit_type.name,
        unitStatus: item.is_active,
        // productQty: item._count.Product,
      };
    });
    // console.log("🚀 ~ data ~ data:", data)
    setUnitdata(data);
  };

  const GetUnitById = async (id) => {
    const res = await unitApi.GetUnitById(id, config);

    if (res.status === 200) {
    //   console.log("🚀 ~ GetUnitById ~ res:", res.data);
      const data = {
        id: res.data.id,
        code: res.data.code,
        name: res.data.name,
        unit_type_id: res.data.unit_type.id,
        is_active: res.data.is_active,
        updated_at: getCurrentDate("/", new Date(res.data.updated_at)),
        updated_by:
          res.data.whos_updated.Employee[0].first_name_en +
          " " +
          res.data.whos_updated.Employee[0].last_name_en,
          productQty: res.data._count.Product,
      };
        // console.log("🚀 ~ GetUnitById ~ data:", data);

      setUniteditdata(data);
      setModaleditunitShow(true);
    }
  };

  const createUnit = async (values) => {
    try {
      const data = {
        business_id: user.businessId,
        code: values.code,
        name: values.name,
        is_active: values.is_active,
        unit_type_id: values.unit_type_id,
        created_by: user.id,
      };

      const res = await unitApi.CreateUnit(data, configCreate);

      if (res.status === 201) {
        setModalsuccessShow(true);
        setModalunitShow(false);
        GetAllUnit();
      }
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const updateUnit = async (values) => {
    // console.log("🚀 ~ updateUnit ~ values:", values);
    try {
      const data = {
        id: values.id,
        code: values.code,
        name: values.name,
        is_active: values.is_active,
        unit_type_id: values.unit_type_id,
        updated_by: user.id,
      };

      const res = await unitApi.UpdateUnit(values.id, data, configCreate);

      if (res.status === 200) {
        setModalsuccessShow(true);
        setModaleditunitShow(false);
        GetAllUnit();
      }
    } catch (error) {
      console.log("Failed to update data:", error);
    }
  };

  const deleteUnit = async (id) => {
    try {
        const res = await unitApi.DeleteUnit(id, config);
    
        if (res.status === 200) {
            setModalsuccessShow(true);
            setModalunitDeleteShow(false);
            GetAllUnit();
        }
        
    } catch (error) {
      console.log("Failed to delete data:", error);
        
    }
  }


  const [filterText, setFilterText] = useState("");
  const filteredItems = unitdata.filter(
    (item) =>
      item.unitName &&
      item.unitName.toLowerCase().includes(filterText.toLowerCase())
  );

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(filteredItems);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "รายชื่อสาขา");
    writeFileXLSX(wb, "รายชื่อสาขา.xlsx");
  };

  return (
    <>
      <nav aria-label="breadcrumb" className="mt-2">
        <ol className="breadcrumb fs-16 ps-0">
          <li className="breadcrumb-item ">
            <a href="#" className="tc-7  text-decoration-none">
              สินค้าและบริการ
            </a>
          </li>
          <li className="breadcrumb-item active tc-1 " aria-current="page">
            {" "}
            จัดการหน่วยนับสินค้า{" "}
          </li>
        </ol>
      </nav>
      <div className="fs-24 tc-7  mb-4 lh-1">จัดการหน่วยนับสินค้า</div>
      <div className="card card-st-2">
        <div className="card-body px-0 py-4">
          <div className="d-flex align-items-center px-4 mb-4">
            <button
              className="btn btn-export-st-1 me-auto"
              onClick={downloadExcel}
            >
              Export
            </button>
            <div className="me-3">
              <input
                type="text"
                className="form-control form-st-4"
                placeholder="ค้นหาชื่อหน่วยนับ"
                onKeyUp={(e) => setFilterText(e.target.value)}
              />
            </div>
            <Button
              type="button"
              className="btn btn btn-st-7"
              onClick={() => setModalunitShow(true)}
            >
              เพิ่มหน่วยนับ
            </Button>
          </div>
          <BusinesUnitTable
            filteredItems={filteredItems}
            setUniteditdata={setUniteditdata}
            setModaleditunitShow={setModaleditunitShow}
            getUnitById={GetUnitById}
          />
        </div>
      </div>
      <CreateModal
        show={modalunitShow}
        onHide={() => setModalunitShow(false)}
        onSubmit={createUnit}
      />
      <UnitEditModal
        show={modaleditunitShow}
        onHide={() => setModaleditunitShow(false)}
        onDelete={() => setModalunitDeleteShow(true)}
        uniteditdata={uniteditdata}
        onSubmit={updateUnit}
      />
      <ConfirmDeleteModal
        show={modalunitDeleteShow}
        onHide={() => setModalunitDeleteShow(false)}
        onConfirm={() => setModalunitDeleteShow(false)}
        onSuccess={() => setModalsuccessShow(true)}
        uniteditdata={uniteditdata}
        onDelete={() => deleteUnit(uniteditdata.id)}
      />
      <SuccessDeleteModal
        show={modalsuccessShow}
        onHide={() => setModalsuccessShow(false)}
      />
    </>
  );
}
